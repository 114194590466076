@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.BlogList {
  background: linear-gradient(180deg, rgb(250, 251, 252) 35%, rgb(217, 217, 217) 100%);
  position: relative;
}
.BlogList .container {
  padding: 56px 100px;
}
@media all and (min-width: 2000px) {
  .BlogList .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .BlogList .container {
    padding: 56px 80px;
  }
}
@media all and (max-width: 992px) {
  .BlogList .container {
    padding: 56px 50px;
  }
}
@media all and (max-width: 768px) {
  .BlogList .container {
    padding: 56px 32px;
  }
}
@media all and (max-width: 576px) {
  .BlogList .container {
    padding: 36px 14px;
  }
}
.BlogList .container .row {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  flex-wrap: wrap;
}
.BlogList .container .row .card {
  width: 300px;
  border: 1px solid rgba(22, 22, 22, 0.3);
  transition: all 0.3s;
  border-radius: 10px;
}
.BlogList .container .row .card:hover .img-container img {
  transform: scale(1.25);
}
.BlogList .container .row .card a {
  text-decoration: none;
  font-weight: 400;
}
.BlogList .container .row .card .img-container {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.BlogList .container .row .card .img-container img {
  width: 100%;
  object-fit: cover;
  height: 200px;
  transform: scale(1.1);
  transition: all 0.3s;
}
.BlogList .container .row .card .content {
  transition: all 0.6s;
  background: linear-gradient(129deg, rgb(134, 133, 133) 0%, rgb(106, 106, 106) 5%, rgba(22, 22, 22, 0.9) 48%, rgba(22, 22, 22, 0.9) 100%);
  border-radius: 0 0 10px 10px;
  padding: 14px 0;
  text-align: center;
}
.BlogList .container .row .card .content a {
  text-decoration: none;
  color: #07C496;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 10px;
  display: inline-block;
}
.BlogList .container .row .card .content p {
  color: #FAFBFC;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
.BlogList .container .row .card .content .bottom-content {
  display: flex;
  justify-content: center;
}
.BlogList .container .row .card .content .bottom-content .editor-dp {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}
.BlogList .container .row .no-blogs-message {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.BlogList .container .row .no-blogs-message h3 {
  font-size: 22px;
  color: #161616;
}
.BlogList .container .load_more {
  display: flex;
  justify-content: center;
  padding: 50px 0 0;
}
.BlogList .container .load_more .loadMore_btn {
  display: inline-block;
  padding: 13px 16px;
  margin-top: 20px;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  border-radius: 24px;
  transition: all 0.3s !important;
  border: none;
}
.BlogList .container .load_more .loadMore_btn:hover {
  transform: translateY(-2px) !important;
  -webkit-box-shadow: -2px 17px 39px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 17px 39px -20px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 17px 39px -20px rgba(0, 0, 0, 0.75);
}

.spinning_loading {
  background: linear-gradient(180deg, rgb(217, 217, 217) 0%, rgb(250, 251, 252) 62%, rgb(250, 251, 252) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30vh 0;
}
.spinning_loading .loader {
  position: relative;
  width: 75px;
  transform: scale(1.5);
  height: 100px;
  margin-top: -100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0);
  background-size: 8px 100%;
  background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  animation: pillerPushUp 4s linear infinite;
}
.spinning_loading .loader:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #07C496;
  border-radius: 50%;
  animation: ballStepUp 4s linear infinite;
}
@keyframes pillerPushUp {
  0%, 40%, 100% {
    background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  }
  50%, 90% {
    background-position: 0px 50px, 15px 58px, 30px 66px, 45px 78px, 60px 90px;
  }
}
@keyframes ballStepUp {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}