@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.PreLoader {
  background: linear-gradient(180deg, rgb(250, 251, 252) 35%, rgb(217, 217, 217) 100%);
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  z-index: 9999;
  position: fixed;
}
.PreLoader .loader {
  position: relative;
  width: 75px;
  transform: scale(1.5);
  height: 100px;
  margin-top: -100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0);
  background-size: 8px 100%;
  background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  animation: pillerPushUp 4s linear infinite;
}
.PreLoader .loader:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #07C496;
  border-radius: 50%;
  animation: ballStepUp 4s linear infinite;
}
@keyframes pillerPushUp {
  0%, 40%, 100% {
    background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  }
  50%, 90% {
    background-position: 0px 50px, 15px 58px, 30px 66px, 45px 78px, 60px 90px;
  }
}
@keyframes ballStepUp {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.PreLoader .img-container {
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
@media all and (max-width: 576px) {
  .PreLoader .img-container {
    top: 54%;
  }
}
.PreLoader .img-container img {
  width: 120px;
}
.PreLoader .middle {
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.PreLoader {
  display: none;
  /* Initially hide the preloader */
}

.PreLoader.visible {
  display: block;
  /* Show the preloader when the 'visible' class is present */
}

.PreLoader.hidden {
  display: none;
  /* Hide the preloader when the 'hidden' class is present */
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}