@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.WorkList {
  background: linear-gradient(180deg, rgb(250, 251, 252) 35%, rgb(217, 217, 217) 100%);
  margin-top: -40px;
}
.WorkList .container {
  padding: 80px 100px;
}
@media all and (min-width: 2000px) {
  .WorkList .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .WorkList .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .WorkList .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .WorkList .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .WorkList .container {
    padding: 50px 14px;
  }
}
.WorkList .container .row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 60px 40px;
}
.WorkList .container .row .card {
  transition: all 0.3s;
  animation: zoom-out 400ms;
  border-radius: 10px;
}
.WorkList .container .row .card a {
  text-decoration: none;
}
.WorkList .container .row .card:hover .img-container img {
  transform: scale(1.2);
}
.WorkList .container .row .card .img-container {
  position: relative;
  overflow: hidden;
  width: 300px;
  border-radius: 10px 10px 0 0;
}
@media all and (max-width: 576px) {
  .WorkList .container .row .card .img-container {
    padding: 0 6px;
  }
}
.WorkList .container .row .card .img-container img {
  height: 240px;
  width: 300px;
  max-width: 100%;
  object-fit: cover;
  transition: all 0.3s;
  aspect-ratio: 5/4;
  transform: scale(1.1);
}
.WorkList .container .row .card .content {
  transition: all 0.6s;
  background: linear-gradient(129deg, rgb(134, 133, 133) 0%, rgb(106, 106, 106) 5%, rgba(22, 22, 22, 0.9) 48%, rgba(22, 22, 22, 0.9) 100%);
  display: flex;
  justify-content: center;
  border-radius: 0 0 10px 10px;
  padding: 14px 0;
  flex-direction: column;
  align-items: center;
  width: 300px;
  text-align: center;
}
.WorkList .container .row .card .content h3 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #07C496;
  font-size: 26px;
  text-transform: capitalize;
}
.WorkList .container .row .card .content p {
  color: #FAFBFC;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin-top: 4px;
}
.WorkList .container .row .no-works-message {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.WorkList .container .row .no-works-message h3 {
  font-size: 22px;
}
.WorkList .container .load_more {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 50px 0 20px;
}
.WorkList .container .load_more button {
  padding: 10px 14px;
  border: none;
  outline: none;
  background-color: white;
  font-weight: 500;
  font-size: 18px;
  transition: 0.3s all;
}
.WorkList .container .load_more button:hover {
  color: white;
}
.WorkList .skeleton-loader {
  width: 350px;
  height: 250px;
  /* Set the height according to your needs */
  background: linear-gradient(90deg, rgba(240, 240, 240, 0.5058823529) 25%, rgba(224, 224, 224, 0.5254901961) 50%, rgba(240, 240, 240, 0.5215686275) 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  /* Optional: Add border-radius for rounded corners */
  margin-bottom: 10px;
  text-indent: -9999px;
}
.WorkList .lozad.lazy-loaded {
  filter: blur(0);
}
@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.WorkList .skeleton-loader {
  width: 300px !important;
  height: 240px !important;
  animation: loading 1.5s infinite;
  margin-bottom: 0 !important;
}

.loading-container {
  background: linear-gradient(180deg, rgb(250, 251, 252) 35%, rgb(217, 217, 217) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 250px 0;
}
.loading-container .loader {
  position: relative;
  width: 75px;
  transform: scale(1.5);
  height: 100px;
  margin-top: -100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0), linear-gradient(#d1d1d1 50px, transparent 0);
  background-size: 8px 100%;
  background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  animation: pillerPushUp 4s linear infinite;
}
.loading-container .loader:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #07C496;
  border-radius: 50%;
  animation: ballStepUp 4s linear infinite;
}
@keyframes pillerPushUp {
  0%, 40%, 100% {
    background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  }
  50%, 90% {
    background-position: 0px 50px, 15px 58px, 30px 66px, 45px 78px, 60px 90px;
  }
}
@keyframes ballStepUp {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}