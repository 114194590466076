@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Mission {
  background: linear-gradient(180deg, rgb(250, 251, 252) 35%, rgb(217, 217, 217) 100%);
}
.Mission .container {
  padding: 80px 100px;
}
@media all and (min-width: 2000px) {
  .Mission .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Mission .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .Mission .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .Mission .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .Mission .container {
    padding: 50px 14px;
  }
}
.Mission .container .mission-section,
.Mission .container .vision-section,
.Mission .container .values-section {
  padding-bottom: 60px;
}
.Mission .container .top-section {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}
.Mission .container .top-section img {
  width: 100px;
  margin-right: 10px;
}
@media all and (max-width: 576px) {
  .Mission .container .top-section img {
    width: 80px;
  }
}
.Mission .container .top-section h2 {
  width: min-content;
  text-transform: uppercase;
  background: linear-gradient(90deg, rgb(70, 102, 246) 0%, rgb(7, 196, 150) 67%, rgb(7, 196, 150) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 54px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
}
@media all and (max-width: 1200px) {
  .Mission .container .top-section h2 {
    font-size: 46px;
  }
}
@media all and (max-width: 992px) {
  .Mission .container .top-section h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 576px) {
  .Mission .container .top-section h2 {
    font-size: 30px;
  }
}
.Mission .container .content ul {
  margin-left: 30px;
}
.Mission .container .content ul .points {
  display: flex;
  padding-bottom: 18px;
}
.Mission .container .content ul .points span img {
  width: 30px;
  transform: translateY(6px) translateX(-12px);
}
.Mission .container .content ul .points p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .Mission .container .content ul .points p {
    font-size: 16px;
  }
}