@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.TimeLine {
  background: linear-gradient(180deg, rgb(250, 251, 252) 35%, rgb(217, 217, 217) 100%);
}
.TimeLine .container {
  padding: 80px 100px;
}
@media all and (min-width: 2000px) {
  .TimeLine .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .TimeLine .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .TimeLine .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .TimeLine .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .TimeLine .container {
    padding: 50px 14px;
  }
}
.TimeLine .container .row .card-container {
  display: flex;
  padding-bottom: 60px;
}
.TimeLine .container .row .card-container .phase {
  margin-right: 60px;
  padding: 16px;
  border-radius: 6px;
  background-color: #07C496;
  position: relative;
  min-width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  -webkit-box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.75);
}
.TimeLine .container .row .card-container .phase h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
.TimeLine .container .row .card-container .phase p {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
@media all and (max-width: 576px) {
  .TimeLine .container .row .card-container .phase {
    margin-right: 35px;
    padding: 10px;
    min-width: none;
  }
}
.TimeLine .container .row .card-container .phase .dot {
  position: absolute;
  right: -37px;
  top: 50%;
  transform: translateY(-50%);
}
@media all and (max-width: 576px) {
  .TimeLine .container .row .card-container .phase .dot {
    right: -26px;
  }
}
.TimeLine .container .row .card-container .phase::before {
  content: "";
  position: absolute;
  height: 180%;
  width: 2px;
  background-color: black;
  right: -30px;
  top: 0;
  bottom: 0;
}
@media all and (max-width: 576px) {
  .TimeLine .container .row .card-container .phase::before {
    right: -19px;
  }
}
.TimeLine .container .row .card-container .content {
  background: linear-gradient(129deg, rgb(134, 133, 133) 0%, rgb(106, 106, 106) 5%, rgba(22, 22, 22, 0.9) 48%, rgba(22, 22, 22, 0.9) 100%);
  border-radius: 6px;
  padding: 14px 14px 40px;
  width: 800px;
  max-width: 100%;
  color: #FAFBFC !important;
  -webkit-box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.75);
}
.TimeLine .container .row .card-container .content p {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 576px) {
  .TimeLine .container .row .card-container .content {
    padding: 10px 10px 40px;
  }
}
.TimeLine .container .row .phase.last::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: black;
  right: -30px;
  top: 0;
  bottom: 0;
}
@media all and (max-width: 576px) {
  .TimeLine .container .row .phase.last::before {
    right: -19px;
  }
}