@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.ProjectIndividual .Navbar .header {
  position: static;
  background: linear-gradient(129deg, rgb(134, 133, 133) 0%, rgb(106, 106, 106) 5%, rgba(22, 22, 22, 0.9) 48%, rgba(22, 22, 22, 0.9) 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
@media all and (max-width: 768px) {
  .ProjectIndividual .Navbar .header ul li a {
    color: #161616 !important;
  }
}
.ProjectIndividual .Navbar .header.active {
  position: fixed;
  background: transparent !important;
  background-color: rgba(250, 251, 252, 0.5137254902) !important;
  backdrop-filter: blur(10px);
  transition: all 0.3s;
}
@media all and (max-width: 768px) {
  .ProjectIndividual .Navbar .header.active {
    backdrop-filter: none !important;
    background: rgba(250, 251, 252, 0.9647058824) !important;
  }
}
.ProjectIndividual .Navbar .header.active ul li a {
  color: #161616 !important;
}
@media all and (max-width: 768px) {
  .ProjectIndividual .Navbar .header.active ul li a {
    color: #161616 !important;
  }
}

.WorkDetails {
  background: linear-gradient(180deg, rgb(217, 217, 217) 0%, rgb(250, 251, 252) 62%, rgb(250, 251, 252) 100%);
}
.WorkDetails .container {
  padding: 80px 100px;
}
@media all and (min-width: 2000px) {
  .WorkDetails .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .WorkDetails .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .WorkDetails .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .WorkDetails .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .WorkDetails .container {
    padding: 50px 14px;
  }
}
.WorkDetails .container .title {
  font-size: 40px;
  padding-bottom: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-transform: capitalize;
  background: linear-gradient(90deg, rgb(70, 102, 246) 0%, rgb(7, 196, 150) 67%, rgb(7, 196, 150) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}
@media all and (max-width: 1200px) {
  .WorkDetails .container .title {
    font-size: 36px;
  }
}
@media all and (max-width: 992px) {
  .WorkDetails .container .title {
    font-size: 34px;
  }
}
@media all and (max-width: 768px) {
  .WorkDetails .container .title {
    font-size: 32px;
  }
}
@media all and (max-width: 576px) {
  .WorkDetails .container .title {
    font-size: 28px;
  }
}
.WorkDetails .container .designation {
  margin-top: -10px;
  padding-bottom: 60px;
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .WorkDetails .container .designation {
    font-size: 16px;
  }
}
.WorkDetails .container .row {
  display: flex;
  justify-content: space-between;
  gap: 0 50px;
}
@media all and (max-width: 1200px) {
  .WorkDetails .container .row {
    flex-direction: column;
  }
}
.WorkDetails .container .row .left-col {
  margin-bottom: 80px;
}
.WorkDetails .container .row .left-col .cover_image {
  width: 350px;
  margin: 20px 0;
}
@media all and (max-width: 576px) {
  .WorkDetails .container .row .left-col .cover_image {
    max-width: 90%;
  }
}
.WorkDetails .container .row .left-col .category {
  color: rgb(129, 129, 129);
  font-size: 18px;
  font-weight: 900;
}
.WorkDetails .container .row .left-col .category span {
  color: rgb(53, 53, 53);
}
.WorkDetails .container .row .left-col p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .WorkDetails .container .row .left-col p {
    font-size: 16px;
  }
}
.WorkDetails .container .row .right-col {
  margin-top: -5px;
}
.WorkDetails .container .row .right-col h2, .WorkDetails .container .row .right-col h3 {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 28px;
}
.WorkDetails .container .row .right-col p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 1em;
}
@media all and (max-width: 992px) {
  .WorkDetails .container .row .right-col p {
    font-size: 16px;
  }
}
.WorkDetails .container .row .right-col ul {
  margin: 24px;
}
.WorkDetails .container .photogallery {
  margin: 80px 0;
  text-align: center;
}
.WorkDetails .container .photogallery h2 {
  font-size: 40px;
  padding-bottom: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  margin: 50px 0;
}
@media all and (max-width: 1200px) {
  .WorkDetails .container .photogallery h2 {
    font-size: 36px;
  }
}
@media all and (max-width: 992px) {
  .WorkDetails .container .photogallery h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 768px) {
  .WorkDetails .container .photogallery h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 576px) {
  .WorkDetails .container .photogallery h2 {
    font-size: 28px;
  }
}
.WorkDetails .container .photogallery .lg-react-element {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.WorkDetails .container .photogallery .lg-react-element .img-container {
  overflow: hidden;
}
.WorkDetails .container .photogallery .lg-react-element .img-container img {
  height: 250px;
  width: 350px;
  object-fit: cover;
  transition: all 0.3s;
  max-width: 99%;
}
.WorkDetails .container .photogallery .lg-react-element .img-container img:hover {
  cursor: pointer;
  filter: brightness(85%);
  transform: scale(1.05);
}
.WorkDetails .plyr {
  max-width: 70%;
}
@media all and (max-width: 576px) {
  .WorkDetails .plyr {
    max-width: 80%;
  }
}

:root {
  --plyr-color-main: #3E979C;
}

.loading-container.work-details {
  background: linear-gradient(180deg, rgb(217, 217, 217) 0%, rgb(250, 251, 252) 62%, rgb(250, 251, 252) 100%);
}