@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.BannerSection {
  position: relative;
}
.BannerSection .img-container {
  height: 400px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.BannerSection .overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(90deg, rgb(36, 36, 36) 0%, rgba(39, 39, 39, 0.8) 26%, rgba(250, 251, 255, 0) 100%);
}
.BannerSection .text h2 {
  background: linear-gradient(90deg, rgb(70, 102, 246) 0%, rgb(7, 196, 150) 67%, rgb(7, 196, 150) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: 40%;
  left: 8%;
  font-size: 64px;
  z-index: 1;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}
@media all and (max-width: 992px) {
  .BannerSection .text h2 {
    font-size: 48px;
  }
}
@media all and (max-width: 768px) {
  .BannerSection .text h2 {
    font-size: 44px;
  }
}
.BannerSection .custom-shape-divider-bottom-1708338597 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 10;
}
.BannerSection .custom-shape-divider-bottom-1708338597 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 90px;
}
@media all and (max-width: 576px) {
  .BannerSection .custom-shape-divider-bottom-1708338597 svg {
    height: 70px;
  }
}
.BannerSection .custom-shape-divider-bottom-1708338597 .shape-fill {
  fill: url(#gradient);
}