@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.KeyMessage {
  background: linear-gradient(180deg, rgb(217, 217, 217) 0%, rgb(250, 251, 252) 62%, rgb(250, 251, 252) 100%);
  margin-top: -5px;
  padding: 50px 0 0;
}
.KeyMessage .container {
  padding: 80px 100px;
  text-align: center;
}
@media all and (min-width: 2000px) {
  .KeyMessage .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .KeyMessage .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .KeyMessage .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .KeyMessage .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .KeyMessage .container {
    padding: 50px 14px;
  }
}
.KeyMessage .container h2 {
  margin-bottom: 40px;
  font-size: 54px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
}
@media all and (max-width: 1200px) {
  .KeyMessage .container h2 {
    font-size: 46px;
  }
}
@media all and (max-width: 992px) {
  .KeyMessage .container h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 576px) {
  .KeyMessage .container h2 {
    font-size: 30px;
  }
}
.KeyMessage .container h2 span {
  background: linear-gradient(90deg, rgb(70, 102, 246) 0%, rgb(7, 196, 150) 67%, rgb(7, 196, 150) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.KeyMessage .container p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .KeyMessage .container p {
    font-size: 16px;
  }
}
.KeyMessage .container a {
  color: white;
  background-color: #07C496;
  display: inline-block;
  padding: 13px 16px;
  margin-top: 20px;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  border-radius: 24px;
  transition: all 0.3s !important;
}
.KeyMessage .container a:hover {
  transform: translateY(-2px) !important;
  -webkit-box-shadow: -2px 17px 39px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 17px 39px -20px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 17px 39px -20px rgba(0, 0, 0, 0.75);
}