@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.WhyChooseUs {
  background: linear-gradient(180deg, rgb(250, 251, 252) 35%, rgb(217, 217, 217) 100%);
}
.WhyChooseUs .container {
  padding: 80px 100px;
}
@media all and (min-width: 2000px) {
  .WhyChooseUs .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .WhyChooseUs .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .WhyChooseUs .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .WhyChooseUs .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .WhyChooseUs .container {
    padding: 50px 14px;
  }
}
.WhyChooseUs .container h2 {
  font-size: 54px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  background: linear-gradient(90deg, rgb(70, 102, 246) 0%, rgb(7, 196, 150) 67%, rgb(7, 196, 150) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  position: relative;
  padding-bottom: 14px;
}
@media all and (max-width: 1200px) {
  .WhyChooseUs .container h2 {
    font-size: 46px;
  }
}
@media all and (max-width: 992px) {
  .WhyChooseUs .container h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 576px) {
  .WhyChooseUs .container h2 {
    font-size: 30px;
  }
}
.WhyChooseUs .container h2::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 4px;
  bottom: 0;
  left: 50%;
  border-radius: 10px;
  transform: translateX(-50%);
  background-color: #07C496;
}
.WhyChooseUs .container .whychooseus-desc {
  padding-top: 40px;
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  text-align: center;
}
@media all and (max-width: 992px) {
  .WhyChooseUs .container .whychooseus-desc {
    font-size: 16px;
  }
}
.WhyChooseUs .container .row {
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: wrap;
  place-items: center;
  gap: 40px;
}
@media all and (max-width: 1200px) {
  .WhyChooseUs .container .row {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (max-width: 768px) {
  .WhyChooseUs .container .row {
    grid-template-columns: 1fr;
  }
}
.WhyChooseUs .container .row .card {
  background: linear-gradient(129deg, rgb(134, 133, 133) 0%, rgb(106, 106, 106) 5%, rgba(22, 22, 22, 0.9) 48%, rgba(22, 22, 22, 0.9) 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(22, 22, 22, 0.3);
  border-radius: 10px;
  max-width: 370px;
  padding: 24px;
  min-height: 370px;
  position: relative;
}
.WhyChooseUs .container .row .card:hover::before {
  visibility: visible;
  opacity: 0.26;
}
.WhyChooseUs .container .row .card:before {
  content: "";
  position: absolute;
  inset: 0;
  background-size: cover;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}
.WhyChooseUs .container .row .card h3 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #07C496;
  font-size: 24px;
  margin-bottom: 8px;
}
.WhyChooseUs .container .row .card p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #FAFBFC !important;
  font-size: 15px;
  padding-bottom: 10px;
}
@media all and (max-width: 992px) {
  .WhyChooseUs .container .row .card p {
    font-size: 16px;
  }
}
.WhyChooseUs .container .row .card span {
  color: #FAFBFC;
  opacity: 0.4;
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  bottom: 14px;
  right: 14px;
}
.WhyChooseUs .container .row .card.strategic::before {
  background: url("../../public/img/whychooseus/premium_photo-1688821131205-52f5c633ce69.avif");
  background-size: cover;
}
.WhyChooseUs .container .row .card.brand::before {
  background: url("../../public/img/whychooseus/photo-1467232004584-a241de8bcf5d.avif");
  background-size: cover;
}
.WhyChooseUs .container .row .card.Personalized::before {
  background: url("../../public/img/whychooseus/premium_photo-1661690088942-d968065868d0.avif");
  background-size: cover;
}
.WhyChooseUs .container .row .card.Excellence::before {
  background: url("../../public/img/whychooseus/premium_photo-1673953509975-576678fa6710.avif");
  background-size: cover;
}
.WhyChooseUs .container .row .card.Track::before {
  background: url("../../public/img/whychooseus/photo-1460925895917-afdab827c52f.avif");
  background-size: cover;
}
.WhyChooseUs .container .row .card.Trends::before {
  background: url("../../public/img/whychooseus/photo-1599658880436-c61792e70672.avif");
  background-size: cover;
}