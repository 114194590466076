@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Founder {
  background-color: #FAFBFC;
}
.Founder h2 {
  font-size: 54px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  background: linear-gradient(90deg, rgb(70, 102, 246) 0%, rgb(7, 196, 150) 67%, rgb(7, 196, 150) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  position: relative;
  padding-bottom: 14px;
}
@media all and (max-width: 1200px) {
  .Founder h2 {
    font-size: 46px;
  }
}
@media all and (max-width: 992px) {
  .Founder h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 576px) {
  .Founder h2 {
    font-size: 30px;
  }
}
.Founder h2::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 4px;
  bottom: 0;
  left: 50%;
  border-radius: 10px;
  transform: translateX(-50%);
  background-color: #07C496;
}
.Founder .container {
  padding: 80px 100px;
}
@media all and (min-width: 2000px) {
  .Founder .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Founder .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .Founder .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .Founder .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .Founder .container {
    padding: 50px 14px;
  }
}
.Founder .container .card-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  background: url("/public/img/abstract-triangle-geometric-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px;
  border-radius: 30px;
  -webkit-box-shadow: -2px 3px 39px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 3px 39px -16px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 3px 39px -16px rgba(0, 0, 0, 0.75);
}
@media all and (max-width: 768px) {
  .Founder .container .card-container {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 50px 20px;
  }
}
.Founder .container .card-container .left-col .img-container img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.418);
}
.Founder .container .card-container .left-col .social-media-icons {
  display: flex;
  justify-content: center;
}
.Founder .container .card-container .left-col .social-media-icons a {
  font-size: 28px;
  margin: 8px;
  color: #161616;
  transition: 0.3s all;
}
.Founder .container .card-container .left-col .social-media-icons a:hover {
  transform: scale(1.2);
}
.Founder .container .card-container .right-col .top-section {
  margin-bottom: 24px;
}
.Founder .container .card-container .right-col .top-section h3 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #4666F6;
  font-size: 32px;
}
.Founder .container .card-container .right-col .top-section h4 {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  opacity: 0.6;
  font-size: 16px;
  text-transform: uppercase;
}
.Founder .container .card-container .right-col p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .Founder .container .card-container .right-col p {
    font-size: 16px;
  }
}