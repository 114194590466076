@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Testimonials {
  position: relative;
  margin-top: -2px;
  position: relative;
  z-index: 1;
  background: linear-gradient(180deg, rgb(250, 251, 252) 35%, rgb(217, 217, 217) 100%);
}
.Testimonials h2 {
  font-size: 54px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-align: center;
  position: relative;
  margin-bottom: 60px;
  background: linear-gradient(90deg, rgb(70, 102, 246) 0%, rgb(7, 196, 150) 67%, rgb(7, 196, 150) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 14px;
}
@media all and (max-width: 1200px) {
  .Testimonials h2 {
    font-size: 46px;
  }
}
@media all and (max-width: 992px) {
  .Testimonials h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 576px) {
  .Testimonials h2 {
    font-size: 30px;
  }
}
.Testimonials h2::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 4px;
  bottom: 0;
  left: 50%;
  border-radius: 10px;
  transform: translateX(-50%);
  background-color: #07C496;
}
.Testimonials .container {
  padding: 80px 100px;
  height: 100%;
}
@media all and (min-width: 2000px) {
  .Testimonials .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Testimonials .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .Testimonials .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .Testimonials .container {
    padding: 50px 14px;
  }
}
.Testimonials .container .main-carousel {
  height: 100%;
}
.Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous {
  margin-left: -7%;
  background-color: #FAFBFC;
  color: #4666F6;
}
@media all and (min-width: 1800px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous {
    margin-left: -4%;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous {
    margin-left: -6%;
  }
}
.Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
  margin-right: -7%;
  background-color: #FAFBFC;
  color: #4666F6;
}
@media all and (min-width: 1800px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    margin-right: -4%;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    margin-right: -6%;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous,
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    width: 36px;
    height: 36px;
  }
}
@media all and (max-width: 768px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous,
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    display: none;
  }
}
.Testimonials .container .main-carousel .carousel-cell {
  width: 33%;
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 60px;
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .carousel-cell {
    width: 100%;
  }
}
@media all and (max-width: 568px) {
  .Testimonials .container .main-carousel .carousel-cell {
    width: 100%;
  }
}
.Testimonials .container .main-carousel .carousel-cell .card {
  background: linear-gradient(129deg, rgb(134, 133, 133) 0%, rgb(106, 106, 106) 5%, rgba(22, 22, 22, 0.9) 48%, rgba(22, 22, 22, 0.9) 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(22, 22, 22, 0.3);
  border-radius: 10px;
  padding: 20px 50px;
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
  text-align: center;
  min-height: 400px;
  position: relative;
  -webkit-box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.75);
  z-index: 2;
}
@media all and (max-width: 568px) {
  .Testimonials .container .main-carousel .carousel-cell .card {
    padding: 16px;
  }
}
.Testimonials .container .main-carousel .carousel-cell .card:hover {
  -webkit-box-shadow: 0px 12px 14px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 12px 14px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 12px 14px -10px rgba(0, 0, 0, 0.75);
}
.Testimonials .container .main-carousel .carousel-cell .card .quote {
  font-size: 100px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.Testimonials .container .main-carousel .carousel-cell .card .quote i {
  font-size: 80px;
  color: rgba(3, 165, 165, 0.1725490196);
}
.Testimonials .container .main-carousel .carousel-cell .card .content {
  padding: 30px 0;
}
@media all and (max-width: 568px) {
  .Testimonials .container .main-carousel .carousel-cell .card .content {
    padding: 20px 0;
  }
}
.Testimonials .container .main-carousel .carousel-cell .card .content p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #FAFBFC;
  font-size: 15px;
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .carousel-cell .card .content p {
    font-size: 16px;
  }
}
@media all and (max-width: 568px) {
  .Testimonials .container .main-carousel .carousel-cell .card .content p {
    font-size: 14px;
  }
}
.Testimonials .container .main-carousel .carousel-cell .card h3 {
  font-size: 18px;
  font-weight: 500;
  color: #07C496;
}
.Testimonials .container .main-carousel .carousel-cell .card .img-container {
  position: relative;
  width: 100%;
}
.Testimonials .container .main-carousel .carousel-cell .card .img-container img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
  margin: 6px 0;
}
.Testimonials .container .main-carousel .carousel-cell .card .img-container .google-icon {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-left: -10px;
  bottom: 4px;
}
.Testimonials .container .main-carousel .carousel-cell .card .five-star-icon i {
  font-size: 13px;
  margin-bottom: 10px;
  color: #f8d50b;
  margin-right: 1px;
}
.Testimonials .container .main-carousel .flickity-page-dots .dot {
  height: 6px;
  width: 30px;
  margin: 2px;
  border-radius: 0;
  transition: all 0.6s ease-in;
  background-color: #07C496;
}
@media all and (max-width: 568px) {
  .Testimonials .container .main-carousel .flickity-page-dots .dot {
    width: 14px;
    height: 6px;
  }
}