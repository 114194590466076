@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
@media all and (max-width: 768px) {
  .BlogSinglePg .Navbar .header ul li a {
    color: #161616 !important;
  }
}
.BlogSinglePg .Navbar .header.active ul li a {
  color: #161616 !important;
}
@media all and (max-width: 768px) {
  .BlogSinglePg .Navbar .header.active ul li a {
    color: #161616 !important;
  }
}

.Blog_HeroSection {
  background: linear-gradient(129deg, rgb(85, 84, 84) 0%, rgb(22, 22, 22) 46%, rgb(22, 22, 22) 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  padding-top: 70px;
}
.Blog_HeroSection .container {
  padding: 80px 100px;
}
@media all and (min-width: 2000px) {
  .Blog_HeroSection .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Blog_HeroSection .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .Blog_HeroSection .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .Blog_HeroSection .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .Blog_HeroSection .container {
    padding: 50px 14px;
  }
}
.Blog_HeroSection .container .row {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 576px) {
  .Blog_HeroSection .container .row {
    flex-direction: column;
  }
}
.Blog_HeroSection .container .row .left-col {
  width: 50%;
}
@media all and (max-width: 576px) {
  .Blog_HeroSection .container .row .left-col {
    width: 100%;
  }
}
.Blog_HeroSection .container .row .left-col h1 {
  font-size: 32px;
  color: #07C496;
  font-family: "Lora", serif;
  font-weight: 600;
}
@media all and (max-width: 992px) {
  .Blog_HeroSection .container .row .left-col h1 {
    font-size: 26px;
  }
}
@media all and (max-width: 768px) {
  .Blog_HeroSection .container .row .left-col h1 {
    font-size: 24px;
  }
}
.Blog_HeroSection .container .row .left-col .text {
  display: flex;
  justify-content: space-between;
  padding-top: 36px;
}
.Blog_HeroSection .container .row .left-col .text .editor-info {
  display: flex;
  align-items: center;
}
.Blog_HeroSection .container .row .left-col .text .editor-info .editor-dp {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.Blog_HeroSection .container .row .left-col .text p {
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.Blog_HeroSection .container .row .left-col .text .editor {
  font-weight: 700;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
@media all and (max-width: 576px) {
  .Blog_HeroSection .container .row .right-col .image-container {
    padding: 30px 0 0;
  }
}
.Blog_HeroSection .container .row .right-col .image-container img {
  width: 300px;
  height: 188px;
  object-fit: contain;
}
@media all and (max-width: 768px) {
  .Blog_HeroSection .container .row .right-col .image-container img {
    width: 250px;
  }
}

.hero-section-skeleton-loading {
  background: linear-gradient(129deg, rgb(134, 133, 133) 0%, rgb(106, 106, 106) 5%, rgba(22, 22, 22, 0.9) 48%, rgba(22, 22, 22, 0.9) 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  padding: 80px 100px;
  padding-top: 120px;
}
@media all and (min-width: 2000px) {
  .hero-section-skeleton-loading {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .hero-section-skeleton-loading {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .hero-section-skeleton-loading {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .hero-section-skeleton-loading {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .hero-section-skeleton-loading {
    padding: 50px 14px;
  }
}
.hero-section-skeleton-loading .row-loading {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 576px) {
  .hero-section-skeleton-loading .row-loading {
    flex-direction: column;
  }
}
.hero-section-skeleton-loading .row-loading .left-col-loading {
  width: 50%;
}
@media all and (max-width: 576px) {
  .hero-section-skeleton-loading .row-loading .left-col-loading {
    width: 100%;
  }
}
.hero-section-skeleton-loading .row-loading .left-col-loading .headline_loading {
  width: 100%;
  margin-top: 40px;
  height: 80px;
  opacity: 0.5;
  transform: translateY(-30px);
  background: linear-gradient(90deg, rgba(240, 240, 240, 0.2196078431) 25%, rgba(224, 224, 224, 0.368627451) 50%, rgba(240, 240, 240, 0.4862745098) 75%);
  background-size: 200% 100%;
  animation: loading 2.3s infinite;
  /* Optional: Add border-radius for rounded corners */
  margin-bottom: 10px;
  text-indent: -9999px;
}
.hero-section-skeleton-loading .row-loading .left-col-loading .headline_loading.date {
  width: 100px;
  margin-top: 6px;
  height: 50px;
  opacity: 0.4;
  transform: translateY(-30px);
  background: linear-gradient(90deg, rgba(240, 240, 240, 0.2196078431) 25%, rgba(224, 224, 224, 0.368627451) 50%, rgba(240, 240, 240, 0.6235294118) 75%);
  background-size: 200% 100%;
  animation: loading 2.3s infinite;
  /* Optional: Add border-radius for rounded corners */
  margin-bottom: 10px;
  text-indent: -9999px;
}
@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
@media all and (max-width: 576px) {
  .hero-section-skeleton-loading .row-loading .left-col-loading .headline_loading.date {
    display: none;
  }
}
.hero-section-skeleton-loading .row-loading .left-col-loading .text-container-loading {
  display: flex;
  justify-content: space-between;
}
.hero-section-skeleton-loading .row-loading .left-col-loading .text-container-loading .p-loading {
  width: 100px;
  margin-top: 6px;
  height: 24px;
  opacity: 0.4;
  transform: translateY(-30px);
  background: linear-gradient(90deg, rgba(240, 240, 240, 0.2196078431) 25%, rgba(224, 224, 224, 0.368627451) 50%, rgba(240, 240, 240, 0.6235294118) 75%);
  background-size: 200% 100%;
  animation: loading 2.3s infinite;
  /* Optional: Add border-radius for rounded corners */
  margin-bottom: 10px;
  text-indent: -9999px;
}
.hero-section-skeleton-loading .row-loading .right-col-loading {
  width: 250px;
}
@media all and (max-width: 576px) {
  .hero-section-skeleton-loading .row-loading .right-col-loading {
    width: 240px;
  }
}
.hero-section-skeleton-loading .row-loading .right-col-loading .image-container-loading {
  width: 100%;
  margin-top: 20px;
  height: 200px;
  transform: translateY(60px);
  opacity: 0.4;
  transform: translateY(-30px);
  background: linear-gradient(90deg, rgba(240, 240, 240, 0.2196078431) 25%, rgba(224, 224, 224, 0.368627451) 50%, rgba(240, 240, 240, 0.6235294118) 75%);
  background-size: 200% 100%;
  animation: loading 2.3s infinite;
  /* Optional: Add border-radius for rounded corners */
  margin-bottom: 10px;
  text-indent: -9999px;
}
@media all and (max-width: 576px) {
  .hero-section-skeleton-loading .row-loading .right-col-loading .image-container-loading {
    height: 150px;
  }
}