@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.PatientsCounter {
  position: relative;
  z-index: 2;
}
.PatientsCounter .counter-container {
  padding: 60px 0;
}
.PatientsCounter .counter-container .counter-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 14px;
}
@media all and (max-width: 768px) {
  .PatientsCounter .counter-container .counter-row {
    grid-template-columns: 1fr;
  }
}
.PatientsCounter .counter-container .counter-row .counter-card {
  background: linear-gradient(129deg, rgb(134, 133, 133) 0%, rgb(106, 106, 106) 5%, rgba(22, 22, 22, 0.9) 48%, rgba(22, 22, 22, 0.9) 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(22, 22, 22, 0.3);
  border-radius: 10px;
  text-align: center;
  padding: 34px 16px;
}
@media all and (max-width: 992px) {
  .PatientsCounter .counter-container .counter-row .counter-card {
    padding: 30px 12px;
  }
}
@media all and (max-width: 768px) {
  .PatientsCounter .counter-container .counter-row .counter-card {
    padding: 30px 12px;
  }
}
.PatientsCounter .counter-container .counter-row .counter-card i {
  font-size: 36px;
  color: #07C496;
}
@media all and (max-width: 992px) {
  .PatientsCounter .counter-container .counter-row .counter-card i {
    font-size: 32px;
  }
}
.PatientsCounter .counter-container .counter-row .counter-card h3 {
  padding: 8px 0;
  color: #FAFBFC;
}
.PatientsCounter .counter-container .counter-row .counter-card span {
  font-weight: 900;
  color: #FAFBFC;
  opacity: 0.9;
  font-size: 26px;
}