@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.HeroSection {
  position: relative;
}
.HeroSection::before {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: saturate(80%);
}
.HeroSection .container video {
  width: 100%;
  height: 700px;
  object-fit: cover;
}
.HeroSection .container .content {
  padding: 80px 100px;
  position: absolute;
  top: 20%;
  width: 80%;
  color: white;
}
@media all and (min-width: 2000px) {
  .HeroSection .container .content {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .HeroSection .container .content {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .HeroSection .container .content {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .HeroSection .container .content {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .HeroSection .container .content {
    padding: 50px 14px;
  }
}
.HeroSection .container .content h1 {
  text-transform: uppercase;
  font-size: 54px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  padding-bottom: 10px;
}
@media all and (max-width: 1200px) {
  .HeroSection .container .content h1 {
    font-size: 46px;
  }
}
@media all and (max-width: 992px) {
  .HeroSection .container .content h1 {
    font-size: 34px;
  }
}
@media all and (max-width: 576px) {
  .HeroSection .container .content h1 {
    font-size: 30px;
  }
}
.HeroSection .container .content h1 span {
  background: linear-gradient(90deg, rgb(70, 102, 246) 0%, rgb(7, 196, 150) 67%, rgb(7, 196, 150) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.HeroSection .container .content p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: white;
}
@media all and (max-width: 992px) {
  .HeroSection .container .content p {
    font-size: 16px;
  }
}